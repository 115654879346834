import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
// import { SsoUtilService } from "../services/sso-util/sso-util.service";
import { take, map } from "rxjs/operators";
import { KeycloakRestService } from "../services/keycloak-util/keycloak-rest.service";
import { KeycloakService } from "keycloak-angular";

@Injectable({
  providedIn: "root",
})
export class MainGuardGuard implements CanActivate {
  storageKey: string = environment.storageKey;

  constructor(
    private keycloakNPM: KeycloakService,
    private keycloak: KeycloakRestService,
    // private sso: SsoUtilService,
    private router: Router // private keycloakSvc: KeycloakService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean| UrlTree | Observable<boolean | UrlTree>  | Promise<boolean | UrlTree> {
    /***
     * check user role if not present redirect to login
     *
     */

    if (this.keycloakNPM.isTokenExpired()) {
      // this.keycloakNPM.logout();
    } else {
      return true;
    }

    //   if(this.keycloak.isTokenPresent()){

    //    if(this.keycloak.getParsedToken() !=null){
    //     const {azp:clientID} = this.keycloak.getParsedToken();

    //     if(this.keycloak.getParsedToken().resource_access[clientID] && this.keycloak.checkTokenValidity()){
    //       return true;
    //     }else{
    //       this.keycloak.closeKCSession();
    //       return this.router.createUrlTree(["/login"]);
    //     }

    //   }else{
    //     this.keycloak.closeKCSession();
    //     return this.router.createUrlTree(["/login"]);
    //   }
    // }else{
    //   this.keycloak.closeKCSession();
    //   return this.router.createUrlTree(["/login"]);
    // }

    // return this.sso.user.pipe(
    //   take(1),
    //   map((user) => {
    //     if (user) {
    //       if (user.rol) {
    //         return true;
    //       }
    //     }
    //     return this.router.createUrlTree(["/login"]);
    //   })
    // );

    // this.keycloakSvc.getKeycloakInstance().authenticated

    // return this.router.createUrlTree(['/main']);
  }
}
