import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment, keycloakConfig } from "src/environments/environment";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {
  KCLogin,
  LoginReqBody,
  KCParsedToken,
  KCClientResouce,
  KCRoleAttrb,
  KCClientObj,
} from "../../models/keycloak.model";
import { QCApplications } from "src/app/models/sso-app.model";

import { Router } from "@angular/router";
import { KeycloakService } from "keycloak-angular";

const { url, clientId, realm, scope } = keycloakConfig;



@Injectable({
  providedIn: "root",
})
export class KeycloakRestService {
  // private storageKey = environment.storageKey;
  // private refreshKey = environment.refreshKey;

  private loginSessionURL: string = `${url}realms/${realm}/protocol/openid-connect/token`;
  private logoutSessionURL: string = `${url}realms/${realm}/protocol/openid-connect/logout`;
  private getIdOfClientURL: string = `${url}admin/realms/${realm}/clients?clientId=${clientId}`;

  private getClientRoleURL: string = `${url}admin/realms/${realm}/clients/<id-client>/roles/`; //Don't touch this <id-client>

  private redirectURL: string = ``;

  private token: string = null;
  private refresh_token: string = null;
  private client_obj: KCClientObj = null;

  private parsedToken: KCParsedToken = null;
  // private tokenSubject: BehaviorSubject<KCParsedToken>;

  private roles: QCApplications[] = [];
  private roleAttrbURLs: string[] = [];

  constructor(
    private keycloakNPM: KeycloakService,
    private http: HttpClient,
    private router: Router
  ) {}

  // public hatdog() {
  //   console.log("This is loginSessionURL"+ this.loginSessionURL);
  //   console.log("This is loginSessionURL"+ this.logoutSessionURL);
  // }

  // public login(loginBody: LoginReqBody): Observable<KCLogin> {
  //   const header = {
  //     headers: new HttpHeaders().set(
  //       "Content-Type",
  //       "application/x-www-form-urlencoded"
  //     ),
  //   };
  //   let reqBodyURL = new URLSearchParams();
  //   reqBodyURL.set("client_id", clientId);
  //   reqBodyURL.set("grant_type", grant_type);
  //   reqBodyURL.set("scope", scope);
  //   reqBodyURL.set("username", loginBody.username);
  //   reqBodyURL.set("password", loginBody.password);

  //   return this.http.post<KCLogin>(this.loginSessionURL, reqBodyURL, header);
  // }

  // public closeKCSession() {
  //   if (sessionStorage.getItem(this.refreshKey)) {
  //     const header = {
  //       headers: new HttpHeaders().set(
  //         "Content-Type",
  //         "application/x-www-form-urlencoded"
  //       ),
  //     };
  //     let reqBodyURL = new URLSearchParams();
  //     reqBodyURL.set("client_id", clientId);
  //     reqBodyURL.set("refresh_token", sessionStorage.getItem(this.refreshKey));

  //     this.http.post(this.logoutSessionURL, reqBodyURL, header).subscribe(
  //       (res) => {},
  //       (err) => {
  //         console.log("Error Logout: ", err);
  //       },
  //       () => {
  //         this.clearSessionStorage();

  //         // this.router.createUrlTree(["/login"]);
  //       }
  //     );
  //   }
  // }

  // public logout(): void {
  //   const header = {
  //     headers: new HttpHeaders().set(
  //       "Content-Type",
  //       "application/x-www-form-urlencoded"
  //     ),
  //   };
  //   let reqBodyURL = new URLSearchParams();
  //   reqBodyURL.set("client_id", clientId);
  //   reqBodyURL.set("refresh_token", sessionStorage.getItem(this.refreshKey));

  //   this.http.post(this.logoutSessionURL, reqBodyURL, header).subscribe(
  //     (res) => {},
  //     (err) => {
  //       console.log("Error Logout: ", err);
  //     },
  //     () => {
  //       this.clearSessionStorage();
  //       location.reload();
  //       this.router.navigate(["/login"]);
  //       // window.on
  //     }
  //   );
  // }

  // private clearSessionStorage() {
  //   sessionStorage.clear();
  // }

  // public setTokens(token: string, refresh_token: string) {
  //   // this.refresh_token = refresh_token;
  //   // this.token = token;

  //   sessionStorage.setItem(this.refreshKey, refresh_token);
  //   sessionStorage.setItem(this.storageKey, token);

  //   this.parsedToken = this.parseJwt(token);

  //   // this.tokenSubject = new BehaviorSubject<KCParsedToken>(this.parsedToken);
  // }

  /**
   * This basically process the token and unparse, and save on this field of this class
   */
  // public initializeKCSvc(){
  //   this.getIdClient().then(res =>{
  //      this.client_obj = res[0];

  //      this.processQCPortalRoles();
  //   });

  // }

  // public initializeKCSvc(){
  //   this.getIdClient().subscribe(res=>{
  //     this.client_obj = res[0];
  //     this.processQCPortalRoles();
  //   })

  // }

  public processQCPortalRoles(client_obj: KCClientObj) {
    this.client_obj = client_obj;
    const clientID = this.getParsedToken().azp;
    const clientResource = this.getParsedToken().resource_access[
      clientID
    ] as KCClientResouce;

    clientResource.roles.forEach((rol) => {
      var url =
        this.getClientRoleURL.replace("<id-client>", this.client_obj.id) + rol;
      this.roleAttrbURLs.push(url);
    });

    // return this.roleAttrbURLs;
  }

  public getRoleAttrbUrl() {
    return this.roleAttrbURLs;
    // this.getRoleAttributes(url).subscribe(res =>{
    //   this.roles.push(this.manipulateRoleAttrib(res));
    // })
  }

  public getRoleAttributes(url: string): Observable<KCRoleAttrb> {
    const header = {
      headers: new HttpHeaders().set(
        "Authorization",
        `Bearer ${this.getToken()}`
      ),
    };
    return this.http.get<KCRoleAttrb>(url, header);
  }

  public getIdClient(): Observable<KCClientObj[]> {
    const header = {
      headers: new HttpHeaders().set(
        "Authorization",
        `Bearer ${this.getToken()}`
      ),
      // .set("Access-Control-Allow-Origin","*")
    };

    return this.http.get<KCClientObj[]>(this.getIdOfClientURL, header);
  }

  public getToken() {
    return this.keycloakNPM.getKeycloakInstance().token;
    // return sessionStorage.getItem(this.storageKey);
    // return this.token;
  }

  public getParsedToken(): KCParsedToken {
    try {
      const storedToken: string = this.getToken();
      const parsedToken: KCParsedToken = this.parseJwt(storedToken);

      return parsedToken;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public manipulateRoleAttrib(roleAttrb: KCRoleAttrb): QCApplications {
    //QCApplications

    var newObj = {
      icon: "",
      id: "",
      name: "",
      url: "",
      description: "",
    };

    // roleAttrb.attributes
    Object.entries(roleAttrb.attributes).map(([key, value]) => {
      console.log(key + " " + value);
      switch (key) {
        case "role_name":
          newObj.name = value[0];
          break;

        case "role_iconurl":
          newObj["icon"] = value[0];
          break;

        case "role_appurl":
          newObj["url"] = value[0];
          break;

        case "role_description":
          newObj["description"] = value[0];
          break;

        default:
          break;
      }
      // newObj.icon =
    });

    return newObj;
  }

  private parseJwt(token: String): KCParsedToken {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    return JSON.parse(window.atob(base64));
  }

  // public isTokenPresent():boolean{
  //   return sessionStorage.getItem(this.storageKey) ? true : false
  // }
  // public checkTokenValidity(): boolean {
  //   /**
  //    * check the token expiration.
  //    * check if  is valid JWT.
  //    *
  //    */
  //   const storedToken: string = sessionStorage.getItem(this.storageKey);
  //   const parsedToken: KCParsedToken = this.parseJwt(storedToken);

  //   const epochSecondsNow = Math.round(new Date().getTime() / 1000);

  //   if (epochSecondsNow > parsedToken.exp) {
  //     //token got expired
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }
}
