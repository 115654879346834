


<div class="container">
  <!-- <div id="header-container" fxShow="true" fxShow.xs="false">
    <div></div>
    <label id="header-text">Welcome to Quezon City</label>
  </div> -->
  <div class="login-container" [ngClass.xs]="'login-full'">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="logo-container">
        <label class="logo-text-header">DSQC APP PORTAL</label>
        <!-- <img src="assets/images/ims-logo.png" alt=""> -->
      </div>
      <div class="logo-text-ins-wrap">
        <label class="logo-text-instruction">Kindly enter your login credentials to access the app portal.</label>
      </div>
      <div class="login-form">
        <input type="text" id="username" formControlName="username" placeholder="Username" [style.background]="getUserIconStyle()">
        <input type="password" id="password" formControlName="password" placeholder="Password" [style.background]="getPasswordIconStyle()">
      </div>
      <!-- <div>
        <span class="error-msg">{{errorMsg}}</span>
      </div> -->
      <div class="login-button-container">
        <button class="login-button" type="submit" [disabled]="!loginForm.valid || isLoading" mat-flat-button color="primary">
          <span *ngIf="!isLoading">Sign In</span> 
          <!-- <div class="spinner-container">
            <mat-spinner *ngIf="isLoading" [diameter]="30"></mat-spinner>
          </div> -->
        </button>
      </div>
      <div class="reset-pass-container" (click)="resetPassword()"> 
        <button disabled>Forgot your password?</button>
      </div>
    </form>
  </div>


  <video playsinline autoplay muted loop poster="assets/images/qc-city-hall.jpg" id="myVideo">
    <source src="assets/video/qc-landmarks-bgvid-compressed.mp4" type="video/mp4">
    Your browser does not support HTML5 video.
  </video>

  <div id="footer-powered-by">
    <div class="footer-powered-by-label"><label>Powered by </label></div>
    
    <img src="assets/images/svi-logo.png">
    <img src="assets/images/enprotec.png">
  </div>

  <div id="footer-qc-ggg" fxShow="true" fxShow.xs="false">
    <img src="assets/images/qc_ggg.png">
  </div>



  <div id="footer-qc-tsm-logo">
    <!-- <img src="assets/images/TFSWM logo.png"> -->

    <h1>Department of Sanitation and Cleanup Works <br> of Quezon City</h1>
  </div>


  <!-- <div id="footer-qc-tfswm-lbl">
      <h1>Department of Sanitation and Cleanup Works <br> of Quezon City</h1>
      
  </div> -->





</div>
