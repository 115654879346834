// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ssoEndpoint: "http://18.204.12.41:8080/sso-webservice", //depricated you should not be concern
  ssoAdminEndPoint: "http://18.204.12.41:8080/ssoadmin", //depricated you should not be concern
  storageKey: "sso-token", //depricated you should not be concern
  // refreshKey: "refresh-token",
  title: "DSQC Portal",
  appName: "Department of Sanitation and Cleanup Works of Quezon City",

};

export const keycloakConfig ={
  url: "https://auth.svi.cloud/auth/",
  realm: "QCENPROTECT",
  clientId: "QC_TFSWM_Portal",
  grant_type: "password",
  silentCheckSsoRedirectUri:
    window.location.origin + "/silent-check-sso.html",
  checkLoginIframe: true,
  checkLoginIframeInterval: 25,
  scope: "openid",
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
