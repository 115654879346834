<form [formGroup]="formGroup">
    <div class="header-div">
        <h3>{{title}}</h3>
    </div>
    <div class="input-div">
        <mat-form-field *ngIf="!reset">
            <input matInput placeholder="{{question1}}" formControlName="secretQuestion1" required>
            <mat-error *ngIf="!reset">Secret Question 1 required</mat-error>    
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="{{answer1Placeholder}}" formControlName="secretAnswer1" required>
            <mat-error>Secret Answer 1 required</mat-error>    
        </mat-form-field>
        <mat-form-field *ngIf="!reset">
            <input matInput placeholder="{{question2}}" formControlName="secretQuestion2" required>
            <mat-error *ngIf="!reset">Secret Question 2 required</mat-error>    
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="{{answer2Placeholder}}" formControlName="secretAnswer2" required>
            <mat-error>Secret Answer 2 required</mat-error>    
        </mat-form-field>
    </div>
</form>