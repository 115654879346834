import { Component, OnInit } from "@angular/core";
import { SsoUtilService } from "./services/sso-util/sso-util.service";
import { ConfigService } from "./services/config/config.service";
import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";


// const REQUESTING_TOKEN = "requesting-token";
// const TOKEN_SHARING_CHANNEL = "token-sharing";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  // bc = new BroadcastChannel(TOKEN_SHARING_CHANNEL);
  title = "app-shell";

  constructor(
    private sso: SsoUtilService,
    private config: ConfigService,
    private keycloakSvc: KeycloakService
  ) {}

  ngOnInit(): void {
    localStorage.clear();
    sessionStorage.clear();

    // this.sso.checkToken();
    // this.sso.addReloadOnTokenDeletion();
  //   this.keycloakSvc.getToken().then((token) => {
  //     console.log(token);
  //   });

  //   var samp = this.keycloakSvc.getKeycloakInstance().tokenParsed;

  //   console.log(samp);

  //   console.log(environment.keycloakConfig);

  //   console.log(this.config.getData());
  // this.addBroadcastChannelListener();
  // this.bc
  }

  // private addBroadcastChannelListener() {
  //   this.bc.addEventListener("message", (event) => {
  //     if (event.data === REQUESTING_TOKEN) {
  //       new BroadcastChannel(TOKEN_SHARING_CHANNEL).postMessage({
  //         accessToken: "this is access token",
  //         refreshToken: "this is refressh token",
  //       });
  //     } else {
  //       const { accessToken, refreshToken } = event.data;
  //       console.log("entererd");

  //       // accessToken && this.tokenStorageService.saveAccessToken(accessToken);
  //       // refreshToken && this.tokenStorageService.saveRefreshToken(refreshToken);
  //     }
  //   });
  // }
}
