<div class="container">
    <mat-drawer-container>
        <mat-drawer mode="side" #sidenav [ngClass.lt-sm]="'drawer-fullWidth'">
            <div class="close-btn-wrapper"> 
                <mat-icon fxShow="false" fxShow.lt-sm="true" (click)="toggle()">chevron_left</mat-icon>
            </div>
            <app-menu></app-menu>
        </mat-drawer>
        <mat-drawer-content>
            <div class="content-container">
                <div class="banner-container">
                    <div class="toggle-button-wrapper" (click)="toggle()">
                        <mat-icon *ngIf="!sidenav.opened; else chevronLeft">menu</mat-icon>
                    </div>
                    <div class="title-container">
                        <div>{{ title }}</div>
                        <div>{{ appName }}</div>
                    </div>
                </div>
                <div class="app-list-container">
                    <div *ngIf="isAppUrlsLoaded | async">
                        <app-list></app-list>
                    </div>
                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>

<ng-template #chevronLeft>
    <mat-icon>chevron_left</mat-icon>
</ng-template>
