<form [formGroup]="formGroup">
    <div class="header-div">
        <h3>{{title}}</h3>
    </div>
    <div class="input-div">
        <mat-form-field (keydown.enter)="$event.preventDefault()">
            <input matInput maxlength="15"  
                formControlName="password"
                placeholder="Password" 
                [type]="hide ? 'password' : 'text'"
                (input)="checkPassCriteria()"
                required>
            <button mat-icon-button matSuffix (click)="hide = !hide" 
                [attr.aria-label]="'Hide password'" 
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="!reset">Invalid Password</mat-error>    
        </mat-form-field>

        <!-- password errors -->
        <mat-card>
            <mat-progress-bar
                [ngClass]="progressClass"
                mode="determinate"
                [value]="progressVal"
                [bufferValue]="20">
            </mat-progress-bar>
            <mat-card-content>
                <div class="info-row">
                    <mat-icon *ngIf="containsLowercase" class="correct-icon">done</mat-icon>
                    <mat-icon *ngIf="!containsLowercase"  class="error-icon">error</mat-icon>
                    <span>contains at least one lowercase character</span>
                </div>
                <div class="info-row">
                    <mat-icon *ngIf="containsUppercase" class="correct-icon">done</mat-icon>
                    <mat-icon *ngIf="!containsUppercase"  class="error-icon">error</mat-icon>
                    <span>contains at least one uppercase character</span>
                </div>
                <div class="info-row">
                    <mat-icon *ngIf="containsNumbers" class="correct-icon">done</mat-icon>
                    <mat-icon *ngIf="!containsNumbers"  class="error-icon">error</mat-icon>
                    <span>contains at least one number</span>
                </div>
                <div class="info-row">
                    <mat-icon *ngIf="containsSpecialChars" class="correct-icon">done</mat-icon>
                    <mat-icon *ngIf="!containsSpecialChars"  class="error-icon">error</mat-icon>
                    <span>contains at least one special character</span>
                </div>
                <div class="info-row">
                    <mat-icon *ngIf="containsMinChars" class="correct-icon">done</mat-icon>
                    <mat-icon *ngIf="!containsMinChars" class="error-icon">error</mat-icon>
                    <span>contains at least 8 characters</span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</form>