import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { take, map } from "rxjs/operators";
// import { SsoUtilService } from "../services/sso-util/sso-util.service";
import { KeycloakRestService } from "../services/keycloak-util/keycloak-rest.service";
import { KeycloakService } from "keycloak-angular";

@Injectable({
  providedIn: "root",
})
export class LoginGuardGuard implements CanActivate {
  constructor(
    // private keycloakNPM: KeycloakService,
    // private keycloak: KeycloakRestService,
    // private sso: SsoUtilService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree  | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // this.keycloakNPM.
    // this.keycloak.login().subscribe();

    /***
     * Checks the role in the token
     *
     */
    // if(this.keycloak.isTokenPresent()){
    //   if(this.keycloak.getParsedToken() !=null){
    //     const {azp:clientID} = this.keycloak.getParsedToken();

    //     if(this.keycloak.getParsedToken().resource_access[clientID] ){
    return this.router.createUrlTree(["/main"]);
    //     }else{
    //       return true;
    //     }

    //   }else{
    //     return true;
    //   }
    // }else{
    //   return true;
    // }

    // return this.sso.user.pipe(
    //   take(1),
    //   map((token) => {
    //     if (token) {
    //       if (token.rol) {
    //         return this.router.createUrlTree(["/main"]);
    //       }
    //     }
    //     return true;
    //   })
    // );
  }
}
