export interface KCLogin{
    access_token:string;
    expires_in:number;
    refresh_expires_in:string;
    refresh_token:string;
    token_type:string;
    id_token:string;
    not_before_policy:string;
    session_state:string;
    scope:string;
}


export interface LoginReqBody{
    username:string,
    password:string
}

export interface KCErrorRes{
    error:string;
    error_description:string;
}

export interface KCClientResouce{
    roles:string[]
}

export interface KCClientObj{
    id:string;
    clientId:string;
    name:string;
}

export interface KCParsedToken {
    acr:string;
    aud:string[];
    auth_time: number;
    azp:string;
    email:string;
    email_verified:boolean;
    exp:number;
    family_name:string;
    given_name:string;
    group_display_name:string[];
    groups:string[];
    iat:number;
    iss:string;
    jti:string;
    name:string;
    nonece:string;
    preferred_username:string;
    realm_access:{
        roles:string[];
    };
    resource_access:string;
    typ:string;


    //old version sso object
    // usr: string;
    // wid?: string;
    // rol?: string[];
    // jti?: string;
    // iss: string;
    // sub: string;
    // cre?: number;
    // iat?: number;
    // exp: number;
}


export interface KCRoleAttrb{
    id:string;
    name:string;
    description:string;
    composite:boolean;
    clientRole:boolean;
    containerId:string;
    attributes:{
        role_name:string[],
        role_iconurl:string[],
        role_appurl:string[],
        role_description:string[],
    }
}
