<div class="container">
  <div class="user-info">
    <div class="profile-photo">
      <img src="assets/images/qc-logo.png" alt="">
    </div>
    <div class="info-container">
      <!-- <h2>{{username | uppercase}}</h2> -->
      <h3 class="name">{{fullname}}</h3>
      <h3 class="role-label">{{roles}}</h3>
    </div>
  </div>   

  <div class="menu-container btn-container">
    <div>
        <button mat-flat-button>
          <img src="assets/images/grid.png" alt="">
          Application
        </button>
    </div>
    <!-- <div>
        <button mat-flat-button>
          <img src="assets/images/gear.png" alt="">
          User Management
        </button>
    </div> -->
  </div>

  <div class="logout-container btn-container">
    <button mat-button (click)="logout()">
        <img src="assets/images/logout.png" alt="">
      Logout
    </button>
  </div>
</div>

