import { Component, OnInit, OnDestroy } from '@angular/core';
import { SsoUtilService } from 'src/app/services/sso-util/sso-util.service';
import { Subscription } from 'rxjs';
import { KeycloakRestService } from 'src/app/services/keycloak-util/keycloak-rest.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit{

  constructor(private keycloak: KeycloakRestService ,private sso: SsoUtilService,private keycloakNPM: KeycloakService) { }

  accountDetailsSubscription: Subscription;

  username: string;
  fullname: string;
  roles: string;

  ngOnInit() {

    var parsedToken = this.keycloak.getParsedToken();

    this.username = parsedToken.preferred_username;
    this.fullname = parsedToken.name;
    this.roles = parsedToken.groups[0];


    // this.sso.getAccountDetails()
    //   .subscribe(user => {
    //     this.username = user.username;
    //     this.fullname = `${user.firstName} ${user.lastName}`;
    //     this.roles = "";
    //     user.roles.forEach(role => {
    //       this.roles = `${this.roles}|${role}`;
    //     })
    //     this.roles = this.roles.slice(1);
    //   });

  }

  logout(){
    this.keycloakNPM.logout();
  }
}
