import { Component, OnInit, ViewChild } from "@angular/core";
import { environment } from "../../../environments/environment";
import { MatDrawer } from "@angular/material/sidenav";
import { KeycloakRestService } from "src/app/services/keycloak-util/keycloak-rest.service";
import { KeycloakService } from "keycloak-angular";
import { KeycloakResourceAccess } from "keycloak-js";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.css"],
})
export class MainComponent implements OnInit {
  title = environment.title;
  appName = environment.appName;

  // appUrls=null;
  isAppUrlsLoaded: Promise<boolean>;

  @ViewChild("sidenav") drawer: MatDrawer;

  constructor(
    private keycloak: KeycloakRestService,
    private keycloakNPM: KeycloakService
  ) {}

  ngOnInit() {
    var key: KeycloakResourceAccess =
      this.keycloakNPM.getKeycloakInstance().resourceAccess;
    // key
    // await this.keycloak.initializeKCSvc();
    this.keycloak.getIdClient().subscribe(
      (res) => {
        this.keycloak.processQCPortalRoles(res[0]);
        // console.log(this.appUrls);
        this.isAppUrlsLoaded = Promise.resolve(true);
      },
      (err) => {
        console.log(err);
        // this.keycloakNPM.logout();
      }
    );
  }

  toggle() {
    this.drawer.toggle();
  }
}
