<div class="container">
  <mat-horizontal-stepper labelPosition="bottom" #stepper linear [ngClass.lt-sm]="'stepper-fullwidth'">
    <mat-step completed="false" editable="false">
      <app-set-secret [title]="setSecretTitle" [question1]="question1" [question2]="question2"
        [answer1]="answer1" [answer2]="answer2"></app-set-secret>
      
      <div class="next-button-container">
          <button class="next-button" mat-flat-button color="primary" type="button" (click)="validateSecretAnswers()">
              <span>Next</span> 
          </button>
      </div>
    </mat-step>
    <mat-step completed="false" editable="false">
      <app-change-password [title]="changePasswordTitle"></app-change-password> 
      
      <div class="next-button-container">
          <button class="next-button" mat-flat-button color="primary" type="button" (click)="sendValues()">
              <span>Next</span> 
          </button>
      </div>
    </mat-step>
    <mat-step completed="false" editable="false">
      <app-display-done [message]="doneMessg"></app-display-done>
      
      <div class="next-button-container">
          <button class="next-button" mat-flat-button color="primary" type="button" (click)="goToPortal()">
              <span>Proceed to Login</span> 
          </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>


  <video playsinline autoplay muted loop poster="assets/images/qc-city-hall.jpg" id="myVideo">
    <source src="assets/video/qc-landmarks-bgvid-compressed.mp4" type="video/mp4">
    Your browser does not support HTML5 video.
  </video>


  <div id="footer-qc-tsm-logo">
    <img src="assets/images/TFSWM logo.png">
    
    <h1>Department of Sanitation and Cleanup Works <br> of Quezon City</h1>
  </div>
</div>