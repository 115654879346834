<div class="app-list-container">
  <mat-progress-spinner [mode]="'indeterminate'" *ngIf="isGettingApps" class="spinner">
  </mat-progress-spinner>

  <div *ngFor="let app of apps" class="app-wrapper">
    <mat-card (click)="openApp(app.url)">
      <!-- <div class="image-container">
        <hr class="hline">
        <div class="logo-container">
          <div class="logo-wrapper">
            <img [src]="getAppIconURL(app.id)" alt="" default="assets/images/icons8.png">
          </div>
        </div>
      </div> -->
      <div class="app-name">
        <div class="app-name-header">
          <h3 class="app-name-text">{{app.name}}</h3>
        </div>
        <img [src]="app.icon" alt="" default="assets/images/icons8.png">
        <div class="app-name-desc">
          <label>{{app.description}}</label>
        </div>
       
      </div>
      
    </mat-card>
  </div>
  <div id="footer-powered-by" fxShow.xs="false" fxShow.sm="false" >
    <div class="footer-powered-by-label"><label>Powered by </label></div>
    
    <img src="assets/images/svi-logo.png">
    <img src="assets/images/enprotec.png">
  </div>

  <div id="footer-qc-ggg" fxShow.xs="false" fxShow.sm="false" >
    <img src="assets/images/qc_ggg.png">
  </div>

</div>


<!-- <div *ngIf="ssoAdminApps" class="app-desc-wrap">
  <div class="app-desc-header"><label>From the selection above, click on the app that you want to use.</label></div>
  <div class="app-desc-list-container">
    <div *ngFor="let app of apps">
      <div class="admin-apps-container">
        <div class="admin-apps-name">
          <label>{{app.name}}</label>
        </div>
        <div class="admin-apps-desc">
          <label>• {{app.description}}</label>
        </div>
      </div>
    </div>
  </div> -->