import { KeycloakService } from "keycloak-angular";
import { KeycloakInitOptions } from "keycloak-js";
import { environment ,keycloakConfig} from "src/environments/environment";

export function initializeKeycloak(keycloak: KeycloakService) {
  const {
    url,
    realm,
    clientId,
    // onLoad,
    // flow,
    silentCheckSsoRedirectUri,
    checkLoginIframe,
    checkLoginIframeInterval,
    scope,
  } = keycloakConfig;

  return () =>
    keycloak.init({
      config: {
        url: url,
        realm: realm,
        clientId: clientId,
      },
      initOptions: {
        onLoad: "login-required",
        flow: "standard",
        silentCheckSsoRedirectUri: silentCheckSsoRedirectUri,
        checkLoginIframe: checkLoginIframe,
        checkLoginIframeInterval: checkLoginIframeInterval,
        scope: scope,
      },
    });
}
